import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  messages: [],
  pushMessageActionStart: false,
  userIds: [],
  title: "",
  description: "",
  channel: "",
  selectedId: null,
  searchText: "",
};

export const pushMessageSlice = createSlice({
  name: "pushMessage",
  initialState,
  reducers: {
    setPushMessages: (state, action) => {
      state.messages = action.payload;
    },
    createMessage: (state, action) => {
      state.messages = [...state.messages, action.payload];
    },
    deleteMessage: (state, action) => {
      state.messages = state.messages.filter(
        (message) => message.id !== action.payload
      );
    },
    setPushMessageActionStart: (state) => {
      state.pushMessageActionStart = true;
    },
    setPushMessageActionFinished: (state) => {
      state.pushMessageActionStart = false;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setMessage: (state, action) => {
      state.description = action.payload;
    },
    setChannel: (state, action) => {
      state.channel = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    selectUsers: (state, action) => {
      state.userIds = [...state.userIds, action.payload];
    },
    removeUsers: (state, action) => {
      state.userIds = Array.from(new Set(state.userIds)).filter(
        (id) => id !== action.payload && id !== undefined
      );
    },
    clearPushMessage: (state) => {
      state.title = "";
      state.description = "";
      state.channel = "";
      state.userIds = [];
      state.searchText = "";
    },
  },
});

export const {
  setPushMessages,
  createMessage,
  deleteMessage,
  setPushMessageActionStart,
  setPushMessageActionFinished,
  setTitle,
  setMessage,
  setChannel,
  selectUsers,
  clearPushMessage,
  removeUsers,
  setSearchText,
} = pushMessageSlice.actions;

import { useEffect, useState } from "react";

export const Calculater = (props) => {
  const { statistics, type } = props;
  const [result, setResult] = useState(0);
  useEffect(() => {
    if (statistics) {
      setResult(
        statistics
          .filter((s) => s.type === type)
          .map((data) =>
            data.opened === 0 || data.count === 0
              ? "0%"
              : `${((data.opened / data.count) * 100).toFixed(2)}%`
          )
      );
    }
  }, [statistics, type]);
  return result;
};

import { createSlice } from "@reduxjs/toolkit";
import { cookie } from "../helper";

const initialState = {
  alertMessage: null,
  email: "",
  password: "",
  loginStarted: null,
  authenticatedUser: undefined,
  token: cookie({ name: "token" }),
};
export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    getMeOk: (state, action) => {
      state.authenticatedUser = action.payload.user;
      state.loginStarted = null;
    },
    getMeFailure: (state) => {
      state.authenticatedUser = undefined;
      state.loginStarted = null;
    },
    loginOk: (state, action) => {
      cookie({ name: "token", value: action.payload.accessToken });
      state.authenticatedUser = action.payload.user;
      state.token = action.payload.accessToken;
      state.loginStarted = null;
    },
    logout: (state) => {
      cookie({ name: "token", value: "" });
      state.authenticatedUser = undefined;
      state.token = null;
    },
    changeEmail: (state, action) => {
      state.email = action.payload;
    },
    changePassword: (state, action) => {
      state.password = action.payload;
    },
    loginStarted: (state) => {
      state.loginStarted = new Date();
    },
    loginError: (state) => {
      state.loginStarted = null;
    },
  },
});

export const {
  getMeOk,
  getMeFailure,
  loginOk,
  logout,
  changeEmail,
  changePassword,
  loginError,
  loginStarted,
} = loginSlice.actions;

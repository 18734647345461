import { createSlice } from "@reduxjs/toolkit";
import { TextObj } from "../text";
import { message } from "antd";

const initialState = {
  requestStart: false,
};

export const baseSlice = createSlice({
  name: "base",
  initialState,
  reducers: {
    timeoutError: (state) => {
      state.errorMessage = message.error(TextObj.SERVER_TIMEOUT_ERROR_TEXT);
      state.requestStart = false;
    },
    requestStart: (state) => {
      state.requestStart = true;
    },
    requestFinished: (state) => {
      state.requestStart = false;
    },
  },
});

export const { timeoutError, requestStart, requestFinished } =
  baseSlice.actions;

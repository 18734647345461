import { Outlet, useNavigate } from "react-router-dom";
import React from "react";
import { UserOutlined } from "@ant-design/icons";
import "./skeleton.css";
import { LeftSideMenu } from "../../constants/features/menu";
import { Button } from "antd";
export const DefaultLayout = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Button
        className="header-profile-button"
        onClick={() => navigate("/profile")}
      >
        <UserOutlined />
      </Button>
      <LeftSideMenu />
      <Outlet />
    </div>
  );
}; 

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  logActionStart: false,
  logs: [],
  page: 1,
  pageSize: 10,
  allPage: 1,
  errorType: "",
  errorStatus: "",
};

export const logsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {
    setLogActionStart: (state) => {
      state.logActionStart = true;
    },
    setLogs: (state, action) => {
      state.logs = action.payload.data;
      state.page = action.payload.page;
      state.pageSize = action.payload.pageSize;
      state.allPage = action.payload.count;
    },
    setLogPage: (state, action) => {
      state.page = action.payload;
    },
    setLogPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setLogErrorType: (state, action) => {
      state.errorType = action.payload;
    },
    setLogErrorStatus: (state, action) => {
      state.errorStatus = action.payload;
    },
    setLogActionEnd: (state) => {
      state.logActionStart = false;
    },
  },
});

export const {
  setLogActionStart,
  setLogs,
  setLogPageSize,
  setLogPage,
  setLogActionEnd,
  setLogErrorType,
  setLogErrorStatus,
} = logsSlice.actions;

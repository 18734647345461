import { store } from "../../constants/store";
import { createCall } from "../base/base";
import { BASE_METHODS } from "../../constants/features/actionTypes";
import {
  setStatActionFinished,
  setStatActionStart,
  setStatistics,
} from "../../constants/reducers/statistic";

export const getAllStatistic = async () => {
  store.dispatch(setStatActionStart());
  const { start, end, type, deviceType, userId, userEmail } =
    store.getState().statistic;
  const queryParams = new URLSearchParams();
  if (start) queryParams.append("start", start.toISOString());
  if (end) queryParams.append("end", end.toISOString());
  if (type) queryParams.append("type", type);
  if (deviceType) queryParams.append("osType", deviceType);
  if (userId) queryParams.append("userId", userId);
  if (userEmail) queryParams.append("userEmail", userEmail);

  const dataToSend = {
    url: "statistic",
    method: BASE_METHODS.GET,
    contentType: "application/json",
    queryParams: queryParams,
  };

  const request = await createCall(dataToSend);
  try {
    const response = request;
    store.dispatch(setStatistics(response.data.statistics));
    store.dispatch(setStatActionFinished());
    return response;
  } catch (error) {
    store.dispatch(setStatActionFinished());
    return null;
  }
};

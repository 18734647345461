import { useMemo } from "react";
import { TextObj } from "../../text";
import { errorTypes } from "../error-types/error-types";

export const LogColumns = ({ handleFilterType, handleFilterStatus }) => {
  return useMemo(() => {
    return [
      {
        title: "Típus",
        key: TextObj.TITLE,
        dataIndex: "title",
        align: "left",
        filterDropdown: ({ setSelectedKeys, selectedKeys }) => (
          <div
            style={{ padding: "8px", overflowY: "auto", maxHeight: "300px" }}
          >
            {errorTypes.map((et) => (
              <div
                key={et.title}
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <label style={{ cursor: "pointer" }}>
                  <input
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    type="radio"
                    value={et.title}
                    checked={selectedKeys[0] === et.title}
                    onChange={(e) => {
                      handleFilterType(e.target.value ?? "");
                      setSelectedKeys(e.target.value ? [e.target.value] : [""]);
                    }}
                  />
                  {et.title.toUpperCase()}
                </label>
              </div>
            ))}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <button
                style={{
                  border: "1px solid black",
                  backgroundColor: "white",
                  borderRadius: "20px",
                  marginRight: "10px",
                  width: "100%",
                }}
                onClick={() => {
                  setSelectedKeys([""]);
                  handleFilterType("");
                }}
              >
                Reset
              </button>
            </div>
          </div>
        ),
      },
      {
        title: TextObj.STATUS,
        key: "status",
        dataIndex: "status",
        align: "left",
        filterDropdown: ({ setSelectedKeys, selectedKeys }) => (
          <div
            style={{ padding: "8px", overflowY: "auto", maxHeight: "300px" }}
          >
            {Array.from(new Set(errorTypes.map((item) => item.status)))
              .map((status) =>
                errorTypes.find((item) => item.status === status)
              )
              .map((et) => (
                <div
                  key={et.status}
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <label style={{ cursor: "pointer" }}>
                    <input
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      type="radio"
                      value={et.status}
                      checked={selectedKeys[0] === et.status}
                      onChange={(e) => {
                        handleFilterStatus(e.target.value ?? "");
                        setSelectedKeys(
                          e.target.value ? [e.target.value] : [""]
                        );
                      }}
                    />
                    {et.status}
                  </label>
                </div>
              ))}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <button
                style={{
                  border: "1px solid black",
                  backgroundColor: "white",
                  borderRadius: "20px",
                  marginRight: "10px",
                  width: "100%",
                }}
                onClick={() => {
                  setSelectedKeys([""]);
                  handleFilterStatus("");
                }}
              >
                Reset
              </button>
            </div>
          </div>
        ),
      },
      {
        title: "Leírás",
        key: TextObj.DESCRIPTION,
        dataIndex: "description",
        align: "left",
        width: "60%",
      },
      {
        title: TextObj.MESSAGES_CREATED_LABEL,
        key: TextObj.MESSAGES_CREATED,
        dataIndex: TextObj.MESSAGES_CREATED,
        render: (record) => {
          return `${new Date(record).toLocaleDateString()} ${new Date(
            record
          ).toLocaleTimeString()}`;
        },

        align: "right",
      },
    ];
  }, [handleFilterType, handleFilterStatus]);
};

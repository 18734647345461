import "./login.css";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeEmail, changePassword } from "../../constants/reducers/login";
import { loginAction } from "../../actions/login";
import { Button, Form, Input } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
export const LoginPage = () => {
  const formRef = React.createRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { email, password, authenticatedUser } = useSelector(
    (state) => state.login
  );

  const handleEmailChange = useCallback(
    (event) => {
      dispatch(changeEmail(event));
    },
    [dispatch]
  );

  const handlePasswordChange = useCallback(
    (event) => {
      dispatch(changePassword(event));
    },
    [dispatch]
  );

  const handleSubmit = async () => {
    await loginAction();
  };

  useEffect(() => {
    if (authenticatedUser) {
      navigate("/users");
    }
  }, [navigate, authenticatedUser]);

  return (
    <div className="login-page-container">
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
        ref={formRef}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Kérem adja meg az e-mail címét!",
            },
            { type: "email", message: "Helytelen formátum!" },
          ]}
          style={{ marginBottom: "10px" }}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="E-mail cím"
            name="email"
            autoComplete="off"
            value={email || ""}
            onChange={(e) => handleEmailChange(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Adja meg a jelszavát!" }]}
          style={{ marginBottom: "10px" }}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder={"Jelszó"}
            name="password"
            value={password || ""}
            onChange={(e) => handlePasswordChange(e.target.value)}
          />
        </Form.Item>
        <Form.Item style={{ width: "100%" }}>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ width: "50%" }}
          >
            Bejelentkezés
          </Button>
        </Form.Item>
      </Form>
      <p className="rights">Minden jog fenntartva. &#169; 2020 Szótár.net</p>
    </div>
  );
};

import { Checkbox, Space } from "antd";
import React from "react";
import { TextObj } from "../../text";
export const CheckBox = (props) => {
  const { setIsCompare } = props;
  return (
    <Space>
      {TextObj.COMPARE}
      <Checkbox onChange={(e) => setIsCompare(e.target.checked)} />
    </Space>
  );
};

export const cookie = (props) => {
  const { name, value } = props;

  if (value !== undefined) {
    document.cookie = `${name}=${value}`;
  }

  let cookie = null;
  document.cookie.split(";").forEach((c) => {
    const [key, value] = c.split("=");
    if (key === name && value !== undefined) {
      cookie = value;
    }
  });

  return cookie;
};

import { Input } from "antd";
import { useEffect, useState } from "react";

export const TextInput = (props) => {
  const { value, name, onChange, validator, errorMessage, type, placeholder } = props;
  const [test, setTest] = useState(false);

  useEffect(() => {
    if (value?.length > 0 && validator) {
      setTest(validator.test(value));
    }
  }, [value, validator]);

  return (
    <div>
      <Input
        value={value}
        name={name}
        onChange={onChange}
        type={type}
        maxLength={255}
        placeholder={placeholder}
      />
      {validator && !test && value?.length > 0 && (
        <span style={{ color: "red" }}>{errorMessage}</span>
      )}
    </div>
  );
};

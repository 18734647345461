import { Select } from "antd";
import { MessageChannels } from "../messageChannels";
import { useDispatch, useSelector } from "react-redux";
import { setChannel } from "../../reducers/push-message";
import { TextObj } from "../../text";

export const ChannelSelector = () => {
  const dispatch = useDispatch();
  const { channel } = useSelector((state) => state.push);

  const handleChannelChange = (value) => {
    dispatch(setChannel(value));
  };

  return (
    <div>
      <Select
        placeholder={TextObj.CHANNEL_SELECTOR_PLACEHOLDER}
        value={channel}
        onChange={handleChannelChange}
      >
        {MessageChannels.map((cahnnel) => {
          return (
            <Select.Option key={cahnnel.value} value={cahnnel.value}>
              {cahnnel.label}
            </Select.Option>
          );
        })}
      </Select>
      <span></span>
    </div>
  );
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userActionStart: false,
  users: [],
  email: "",
  password: "",
  passwordCheck: "",
  selectedId: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserActionStart: (state) => {
      state.userActionStart = true;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setPasswordCheck: (state, action) => {
      state.passwordCheck = action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    createUser: (state, action) => {
      state.users = [...state.users, action.payload];
    },
    deleteUser: (state) => {
      state.users = state.users.filter((user) => user.id !== state.selectedId);
    },
    setUserActionEnd: (state) => {
      state.userActionStart = false;
    },
    resetUser: (state) => {
      state.email = "";
      state.password = "";
      state.passwordCheck = "";
    },
  },
});

export const {
  setUserActionStart,
  setUsers,
  setEmail,
  setPassword,
  setPasswordCheck,
  setSelectedId,
  createUser,
  deleteUser,
  setUserActionEnd,
  resetUser
} = userSlice.actions;

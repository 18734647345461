import { message } from "antd";
import { store } from "../../constants/store";
import { createCall } from "../base";
import { TextObj } from "../../constants/text";
import { BASE_METHODS } from "../../constants/features/actionTypes";
import { loginOk, loginStarted } from "../../constants/reducers/login";

export const loginAction = async () => {
  store.dispatch(loginStarted());
  const { email, password } = store.getState().login;

  const params = {
    email: email,
    password: password,
  };
  const dataToSend = {
    url: "users/login",
    method: BASE_METHODS.POST,
    contentType: "application/json",
    body: params,
    skip: true,
  };

  const response = await createCall(dataToSend);

  if (response.data) {
    store.dispatch(
      loginOk({
        user: response.data.user,
        accessToken: response.data.accessToken,
      })
    );
    message.success("Sikeres bejelentkezés");
  } else {
    const errorResponeData = response.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 1002:
          errorMessage = TextObj.INVALID_EMAIL_PASSWORD;
          break;
        default:
          break;
      }
      message.error(errorMessage);
      return;
    }
  }
};

import { Col, Row, Space } from "antd";
import React, { useCallback, useEffect, useState } from "react";

import { PlusCircleFilled } from "@ant-design/icons";
import { MessagesTable } from "../../constants/features/table";
import { CustomButton } from "../../constants/features/button";
import { MessagesModal } from "../../constants/features/modal";
import { TextInput } from "../../constants/features/input";
import { useDispatch, useSelector } from "react-redux";
import { setSearchText } from "../../constants/reducers/push-message";
import { getAllPushMessage } from "../../actions/pushMessage/get-all-push-message";
import { TextObj } from "../../constants/text";

export const Messages = () => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const { searchText } = useSelector((state) => state.push);
  const handleCreate = () => {
    setShowModal(true);
  };

  const handleSearchTextChange = useCallback(
    (event) => {
      dispatch(setSearchText(event.target.value));
    },
    [dispatch]
  );

  const getSearch = async () => {
    await getAllPushMessage();
  };

  useEffect(() => {
    getSearch();
  }, [searchText]);

  return (
    <div style={{ paddingLeft: "300px", paddingTop: "80px" }}>
      <Row style={{ padding: "0.5em" }}>
        <Col span={24}>
          <Space>
            <CustomButton
              type="primary"
              onClick={handleCreate}
              buttonText={
                <Space>
                  <PlusCircleFilled />
                  Új üzenet
                </Space>
              }
            />
            <div style={{ width: "20em" }}>
              <TextInput
                value={searchText}
                onChange={handleSearchTextChange}
                placeholder={TextObj.SEARCH}
              />
            </div>
          </Space>
        </Col>
      </Row>
      <MessagesTable />
      <MessagesModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

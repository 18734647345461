import { store } from "../../constants/store";
import { createCall } from "../base/base";
import { BASE_METHODS } from "../../constants/features/actionTypes";
import {
  setDictionaryUsers,
  setDistionaryUsersActionFinish,
  setDistionaryUsersActionStart,
} from "../../constants/reducers/ductionary-users";
export const getAllDictionaryUser = async () => {
  store.dispatch(setDistionaryUsersActionStart());
  const dataToSend = {
    url: "mobile/all",
    method: BASE_METHODS.GET,
    contentType: "application/json",
  };
  const request = await createCall(dataToSend);
  try {
    const response = request;
    store.dispatch(setDictionaryUsers(response.data.users));
    store.dispatch(setDistionaryUsersActionFinish());
    return response;
  } catch (error) {
    store.dispatch(setDistionaryUsersActionFinish());
    return;
  }
};

import { DatePicker, message } from "antd";
import React, { useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { TextObj } from "../../text";
import {
  setStatisticCompareEnd,
  setStatisticEnd,
} from "../../reducers/statistic";

export const DatePickerForm = (props) => {
  const dispatch = useDispatch();
  const { onChange, value } = props;
  const { start, end, compareStart, compareEnd } = useSelector(
    (state) => state.statistic
  );

  const disabledDate = (current) => {
    return current > moment().endOf("day");
  };

  useEffect(() => {
    if (end) {
      if (start > end) {
        message.error(TextObj.END_DATE_CANNOT_BE_LOWER);
        dispatch(setStatisticEnd(null));
      }
    }
    if (compareEnd) {
      if (compareStart > compareEnd) {
        message.error(TextObj.END_DATE_CANNOT_BE_LOWER);
        dispatch(setStatisticCompareEnd(null));
      }
    }
  }, [dispatch, start, end, compareEnd, compareStart]);

  return (
    <DatePicker
      value={value ?? null}
      style={{ width: "100%" }}
      onChange={onChange}
      placeholder={new Date(moment().add(-1, "day")).toLocaleDateString()}
      disabledDate={disabledDate}
      allowClear={false}
    />
  );
};

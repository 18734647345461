import { ArrowUpOutlined } from "@ant-design/icons";
import React from "react";
export const OpenedMessagesCalculator = (props) => {
  const { sentMessageOne, openedMessageOne, sentMessageTwo, openedMessageTwo } =
    props;

  const sentSumOne = sentMessageOne.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);

  const openedSumOne = openedMessageOne.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);

  const sentSumTwo = sentMessageTwo.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);

  const openedSumTwo = openedMessageTwo.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);

  const diffOne = (openedSumOne / sentSumOne) * 100;
  const diffTwo = (openedSumTwo / sentSumTwo) * 100;

  return (
    diffOne - diffTwo >= 0 &&
    ((diffOne - diffTwo).toFixed(2) === "0.00" ? (
      <div style={{ color: "green" }}>
        0% <ArrowUpOutlined />
      </div>
    ) : (
      <div style={{ color: "green" }}>
        {(diffOne - diffTwo).toFixed(2)} % <ArrowUpOutlined />
      </div>
    ))
  );
};

import { ArrowUpOutlined } from "@ant-design/icons";
import React from "react";
export const DifferenceCalculator = (props) => {
  const { statOne, statTwo } = props;

  const sumOne = statOne.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);

  const sumTwo = statTwo.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);

  return (
    ((sumOne / sumTwo) * 100).toFixed(2) >= 100 && (
      <div style={{ color: "green" }}>
        {((sumOne / sumTwo) * 100).toFixed(2) === "100.00"
          ? 0
          : ((sumOne / sumTwo) * 100).toFixed(2)}
        % <ArrowUpOutlined />
      </div>
    )
  );
};

import { Select } from "antd";

export const DeviceSearch = (props) => {
  const { devicetype, setDeviceType } = props;
  return (
    <Select
      value={devicetype}
      onChange={(e) => setDeviceType(e)}
      placeholder={"Mindkettő"}
    >
      <Select.Option value={0} key={0}>
        Mindkettő
      </Select.Option>
      <Select.Option value={1} key={1}>
        IOS
      </Select.Option>
      <Select.Option value={2} key={2}>
        Android
      </Select.Option>
    </Select>
  );
};

import { LogTable } from "../../constants/features/table";

export const Logs = () => {
  return (
    <div
      style={{
        paddingLeft: "300px",
        top: 0,
        paddingTop: "40px",
      }}
    >
      <LogTable />
    </div>
  );
};

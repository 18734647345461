import { PieChart } from "./chart";
import React from "react";
import {
  AllMessageCounter,
  Calculater,
  DifferenceCalculator,
  FailedCalculator,
  OpenedMessagesCalculator,
} from "../utils";
import { TextObj } from "../../text";
import { Space } from "antd";
import "./style.css";
export const PieCharts = (props) => {
  const { data, compareData, type } = props;
  return (
    <div>
      <div className="mainChartsPositions">
        <PieChart
          title={TextObj.CASE_TWO}
          maxStatType={7}
          hideStat={[14, 30]}
          data={data
            .filter(
              (s) =>
                (s.type === 201 ||
                  s.type === 202 ||
                  s.type === 203 ||
                  s.type === 207) ??
                s.type
            )
            .map((d) => ({
              count: d.count,
              megnyitva: d.opened,
              type:
                d.type === 201
                  ? TextObj.DAILY
                  : d.type === 202
                  ? TextObj.SECOND_DAY
                  : d.type === 203
                  ? TextObj.THIRD_DAY
                  : TextObj.WEEKLY,
            }))}
          dailyStat={Calculater({ statistics: data, type: 201 })}
          twoDayStat={Calculater({ statistics: data, type: 202 })}
          threeDayStat={Calculater({ statistics: data, type: 203 })}
          weeklyStat={Calculater({ statistics: data, type: 207 })}
          dailyFailed={FailedCalculator({ statistics: data, type: 201 })}
          twoDayFailed={FailedCalculator({ statistics: data, type: 202 })}
          threeDayFailed={FailedCalculator({ statistics: data, type: 203 })}
          weeklyFailed={FailedCalculator({ statistics: data, type: 207 })}
        />
        {!compareData && type === 2 && (
          <div>
            <div>
              {AllMessageCounter({
                stat: data
                  .filter(
                    (s) =>
                      (s.type === 201 ||
                        s.type === 202 ||
                        s.type === 203 ||
                        s.type === 207) ??
                      s.type
                  )
                  .map((s) => s.count),
                title: data.length > 0 ? TextObj.ALL_SENT_MESSAGE : "",
              })}
            </div>
            <div>
              {AllMessageCounter({
                stat: data
                  .filter(
                    (s) =>
                      (s.type === 201 ||
                        s.type === 202 ||
                        s.type === 203 ||
                        s.type === 207) ??
                      s.type
                  )
                  .map((s) => s.opened),
                title: data.length > 0 ? TextObj.ALL_OPENED_MESSAGE : "",
              })}
            </div>
          </div>
        )}
      </div>

      <div className="mainChartsPositions">
        <PieChart
          title={TextObj.CASE_ONE}
          hideStat={[2, 3]}
          maxStatType={90}
          data={data
            .filter((s) => !(s.type > 190))
            .map((d) => ({
              count: d.count,
              megnyitva: d.opened,
              type:
                d.type === 101
                  ? TextObj.DAILY
                  : d.type === 107
                  ? TextObj.WEEKLY
                  : d.type === 114
                  ? TextObj.SECOND_WEEK
                  : d.type === 130
                  ? TextObj.MONTHLY
                  : d.type === 160
                  ? TextObj.SECOND_MONTH
                  : TextObj.QUARTER_YEAR,
            }))}
          dailyStat={Calculater({ statistics: data, type: 101 })}
          weeklyStat={Calculater({ statistics: data, type: 107 })}
          twoWeeklyStat={Calculater({ statistics: data, type: 114 })}
          monthlyStat={Calculater({ statistics: data, type: 130 })}
          twoMonthlyStat={Calculater({ statistics: data, type: 160 })}
          quarterStat={Calculater({ statistics: data, type: 190 })}
          dailyFailed={FailedCalculator({ statistics: data, type: 101 })}
          weeklyFailed={FailedCalculator({ statistics: data, type: 107 })}
          twoWeeklyFailed={FailedCalculator({ statistics: data, type: 114 })}
          monthlyFailed={FailedCalculator({ statistics: data, type: 130 })}
          twoMonthlyFailed={FailedCalculator({ statistics: data, type: 160 })}
          quarterFailed={FailedCalculator({ statistics: data, type: 190 })}
        />
        {!compareData && type === 1 && (
          <div>
            <div>
              {AllMessageCounter({
                stat: data.filter((s) => !(s.type > 190)).map((s) => s.count),
                title: data.length > 0 ? TextObj.ALL_SENT_MESSAGE : "",
              })}
            </div>
            <div>
              {AllMessageCounter({
                stat: data.filter((s) => !(s.type > 190)).map((s) => s.opened),
                title: data.length > 0 ? TextObj.ALL_OPENED_MESSAGE : "",
              })}
            </div>
          </div>
        )}
      </div>

      <div className="mainChartsPositions">
        <PieChart
          title={TextObj.CASE_THREE}
          maxStatType={30}
          hideStat={[2, 3]}
          data={data
            .filter(
              (s) =>
                (s.type === 301 ||
                  s.type === 307 ||
                  s.type === 314 ||
                  s.type === 330) ??
                s.type
            )
            .map((d) => ({
              count: d.count,
              megnyitva: d.opened,
              type:
                d.type === 301
                  ? TextObj.DAILY
                  : d.type === 307
                  ? TextObj.WEEKLY
                  : d.type === 314
                  ? TextObj.SECOND_WEEK
                  : TextObj.MONTHLY,
            }))}
          dailyStat={Calculater({ statistics: data, type: 301 })}
          weeklyStat={Calculater({ statistics: data, type: 307 })}
          twoWeeklyStat={Calculater({ statistics: data, type: 314 })}
          monthlyStat={Calculater({ statistics: data, type: 330 })}
          dailyFailed={FailedCalculator({ statistics: data, type: 301 })}
          weeklyFailed={FailedCalculator({ statistics: data, type: 307 })}
          twoWeeklyFailed={FailedCalculator({ statistics: data, type: 314 })}
          monthlyFailed={FailedCalculator({ statistics: data, type: 330 })}
        />
        {!compareData && type === 3 && (
          <div>
            <div>
              {AllMessageCounter({
                stat: data
                  .filter(
                    (s) =>
                      (s.type === 301 ||
                        s.type === 307 ||
                        s.type === 314 ||
                        s.type === 330) ??
                      s.type
                  )
                  .map((s) => s.count),
                title: data.length > 0 ? TextObj.ALL_SENT_MESSAGE : "",
              })}
            </div>
            <div>
              {AllMessageCounter({
                stat: data
                  .filter(
                    (s) =>
                      (s.type === 301 ||
                        s.type === 307 ||
                        s.type === 314 ||
                        s.type === 330) ??
                      s.type
                  )
                  .map((s) => s.opened),
                title: data.length > 0 ? TextObj.ALL_OPENED_MESSAGE : "",
              })}
            </div>
          </div>
        )}
      </div>

      <div className="mainChartsPositions">
        <PieChart
          title={TextObj.CASE_FOUR}
          hideStat={[2, 3]}
          maxStatType={90}
          data={data
            .filter(
              (s) =>
                (s.type === 501 ||
                  s.type === 507 ||
                  s.type === 514 ||
                  s.type === 530 ||
                  s.type === 560 ||
                  s.type === 590) ??
                s.type
            )
            .map((d) => ({
              count: d.count,
              megnyitva: d.opened,
              type:
                d.type === 501
                  ? TextObj.DAILY
                  : d.type === 507
                  ? TextObj.WEEKLY
                  : d.type === 514
                  ? TextObj.SECOND_WEEK
                  : d.type === 530
                  ? TextObj.MONTHLY
                  : d.type === 560
                  ? TextObj.SECOND_MONTH
                  : TextObj.QUARTER_YEAR,
            }))}
          dailyStat={Calculater({ statistics: data, type: 501 })}
          weeklyStat={Calculater({ statistics: data, type: 507 })}
          twoWeeklyStat={Calculater({ statistics: data, type: 514 })}
          monthlyStat={Calculater({ statistics: data, type: 530 })}
          twoMonthlyStat={Calculater({ statistics: data, type: 560 })}
          quarterStat={Calculater({ statistics: data, type: 590 })}
          dailyFailed={FailedCalculator({ statistics: data, type: 501 })}
          weeklyFailed={FailedCalculator({ statistics: data, type: 507 })}
          twoWeeklyFailed={FailedCalculator({ statistics: data, type: 514 })}
          monthlyFailed={FailedCalculator({ statistics: data, type: 530 })}
          twoMonthlyFailed={FailedCalculator({ statistics: data, type: 560 })}
          quarterFailed={FailedCalculator({ statistics: data, type: 590 })}
        />
        {!compareData && type === 5 && (
          <div>
            <div>
              {AllMessageCounter({
                stat: data
                  .filter(
                    (s) =>
                      (s.type === 501 ||
                        s.type === 507 ||
                        s.type === 514 ||
                        s.type === 530 ||
                        s.type === 560 ||
                        s.type === 590) ??
                      s.type
                  )
                  .map((s) => s.count),
                title: data.length > 0 ? TextObj.ALL_SENT_MESSAGE : "",
              })}
            </div>
            <div>
              {AllMessageCounter({
                stat: data
                  .filter(
                    (s) =>
                      (s.type === 501 ||
                        s.type === 507 ||
                        s.type === 514 ||
                        s.type === 530 ||
                        s.type === 560 ||
                        s.type === 590) ??
                      s.type
                  )
                  .map((s) => s.opened),
                title: data.length > 0 ? TextObj.ALL_OPENED_MESSAGE : "",
              })}
            </div>
          </div>
        )}
      </div>
      {compareData && (
        <ul>
          <li>
            <Space>
              {AllMessageCounter({
                stat: data.map((s) => s.count),
                title: TextObj.ALL_SENT_MESSAGE,
              })}
              <DifferenceCalculator
                statOne={data.map((s) => s.count)}
                statTwo={compareData.map((s) => s.count)}
              />
            </Space>
          </li>
          <li>
            <Space>
              {AllMessageCounter({
                stat: data.map((s) => s.opened),
                title: TextObj.ALL_OPENED_MESSAGE,
              })}
              <OpenedMessagesCalculator
                sentMessageOne={data.map((s) => s.count)}
                openedMessageOne={data.map((s) => s.opened)}
                sentMessageTwo={compareData.map((s) => s.count)}
                openedMessageTwo={compareData.map((s) => s.opened)}
              />
            </Space>
          </li>
        </ul>
      )}

      <div>
        {!(data?.length > 0) && !(compareData?.length > 0) && TextObj.NO_DATA}
      </div>
    </div>
  );
};

export const PriorityOptions = [
  {
    value: null,
    label: "Összes",
  },
  {
    value: 2,
    label: "Prio 1",
  },
  {
    value: 3,
    label: "Prio 2",
  },
  {
    value: 5,
    label: "Prio 3",
  },
  {
    value: 1,
    label: "Prio 4",
  },
];

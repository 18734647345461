import { useDispatch } from "react-redux";
import { resetUser } from "../../reducers/user";

import { Modal } from "antd";
import { AdminForm } from "../form";
import { TextObj } from "../../text";

export const AdminModal = (props) => {
  const dispatch = useDispatch();
  const { showModal, setShowModal } = props;
  const handleCancel = () => {
    setShowModal(false);
    dispatch(resetUser());
  };

  return (
    <Modal
      className="modal title"
      open={showModal}
      title={TextObj.CREATE}
      onCancel={handleCancel}
      footer={null}
      forceRender
      centered
    >
      <AdminForm handleCancel={handleCancel} />
    </Modal>
  );
};

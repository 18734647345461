import { useDispatch, useSelector } from "react-redux";
import { setMessage, setTitle } from "../../reducers/push-message";
import { useCallback } from "react";
import { createPushMessage } from "../../../actions/pushMessage/create-push-message";
import { formItemLayout, tailFormItemLayout } from "../formItemLayout";
import { TextObj } from "../../text";
import { Form, Space } from "antd";
import { CustomButton } from "../button";
import { SaveFilled } from "@ant-design/icons";
import { ChannelSelector, UserSelector } from "../select";
import { TextInput } from "../input";

export const MessagesForm = (props) => {
  const dispatch = useDispatch();
  const { title, description, userIds } = useSelector((state) => state.push);
  const { handleCancel } = props;

  const handleTitleChange = useCallback(
    (value) => {
      dispatch(setTitle(value));
    },
    [dispatch]
  );

  const handleDescriptionChange = useCallback(
    (value) => {
      dispatch(setMessage(value));
    },
    [dispatch]
  );

  const handleSave = async () => {
    await createPushMessage();
    handleCancel();
  };

  return (
    <Form name="sender" {...formItemLayout} scrollToFirstError>
      <Form.Item
        label={TextObj.EMAIL}
        name={[TextObj.FORM_EMAIL_NAME, TextObj.FORM_EMAIL_NAME]}
      >
        <UserSelector type={"multiple"}/>
      </Form.Item>
      <Form.Item label={TextObj.MESSAGES_TYPE_LABEL} name={TextObj.TYPE}>
        <ChannelSelector />
      </Form.Item>
      <Form.Item
        label={TextObj.MESSAGES_TITLE}
        name={[TextObj.FORM_EMAIL_NAME, TextObj.TITLE]}
        hasFeedback
      >
        <TextInput
          value={title}
          name={TextObj.MESSAGES_TITLE}
          onChange={(e) => handleTitleChange(e.target.value)}
          errorMessage={TextObj.MESSAGES_TITLE_ERROR}
          type={TextObj.TEXT_INPUT_TYPE}
        />
      </Form.Item>
      <Form.Item
        label={TextObj.MESSAGES_MESSAGE_LABEL}
        name={[TextObj.FORM_EMAIL_NAME, TextObj.DESCRIPTION]}
        hasFeedback
      >
        <TextInput
          value={description}
          name={TextObj.DESCRIPTION}
          onChange={(e) => handleDescriptionChange(e.target.value)}
          errorMessage={TextObj.MESSAGES_DESCRIPTION_ERROR}
          type={TextObj.TEXT_AREA}
        />
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Space>
          <CustomButton
            key={TextObj.CANCEL_BUTTON_KEY}
            onClick={handleCancel}
            buttonText={TextObj.CANCEL}
          />
          <CustomButton
            type={TextObj.PRIMARY_BUTTON_TYPE}
            htmlType={TextObj.SUBMIT_HTML_BUTTON_TYPE}
            key={TextObj.SAVE_BUTTON_KEY}
            disabled={!userIds?.length === 0 || !description || !title}
            onClick={handleSave}
            buttonText={
              <>
                <SaveFilled />
                {TextObj.SAVE}
              </>
            }
          />
        </Space>
      </Form.Item>
    </Form>
  );
};

import { Radio } from "antd";
import React from "react";
import { TextObj } from "../../text";
export const RadioGroupButtons = (props) => {
  const { setIsRange, isRange } = props;

  return (
    <Radio.Group value={isRange} onChange={(e) => setIsRange(e.target.value)}>
      <Radio value={false}>{TextObj.DATE_SORT_DATA_SEARCH}</Radio>
      <Radio value={true}>{TextObj.RANGE_SORT_DATA_SEARCH}</Radio>
    </Radio.Group>
  );
};

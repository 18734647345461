import { Form } from "antd";
import { DatePickerForm, DeviceSearch, Selector } from "../items";
import { UserSelector } from "../select";
import { useCallback } from "react";
import {
  setCompareDeviceType,
  setDeviceType,
  setStatisticCompareEnd,
  setStatisticCompareStart,
  setStatisticCompareType,
  setStatisticEnd,
  setStatisticStart,
  setStatisticType,
} from "../../reducers/statistic";
import { useDispatch, useSelector } from "react-redux";
import { statisticFormItemLayout } from "../formItemLayout";
import { TextObj } from "../../text";

export const StatisticForm = (props) => {
  const dispatch = useDispatch();
  const {
    deviceType,
    compareDeviceType,
    start,
    end,
    compareStart,
    compareEnd,
    type,
    compareType,
  } = useSelector((state) => state.statistic);
  const { isRange, isCompare } = props;
  const handleStartDateChange = useCallback(
    (value) => {
      if (value === null) {
        const now = new Date();
        dispatch(setStatisticStart(new Date(now.setDate(now.getDate() - 1))));
      } else {
        dispatch(setStatisticStart(value));
      }
    },
    [dispatch]
  );

  const handleEndDateChange = useCallback(
    (value) => {
      dispatch(setStatisticEnd(value));
    },
    [dispatch]
  );

  const handleTypeChange = useCallback(
    (value) => {
      dispatch(setStatisticType(value));
    },
    [dispatch]
  );

  const handleChangeDeviceType = useCallback(
    (value) => {
      dispatch(setDeviceType(value));
    },
    [dispatch]
  );

  const handleCompareStartDateChange = useCallback(
    (value) => {
      dispatch(setStatisticCompareStart(value));
    },
    [dispatch]
  );

  const handleCompareEndDateChange = useCallback(
    (value) => {
      dispatch(setStatisticCompareEnd(value));
    },
    [dispatch]
  );

  const handleCompareTypeChange = useCallback(
    (value) => {
      dispatch(setStatisticCompareType(value));
    },
    [dispatch]
  );

  const handleChangeCompareDeviceType = useCallback(
    (value) => {
      dispatch(setCompareDeviceType(value));
    },
    [dispatch]
  );

  return (
    <Form
      {...statisticFormItemLayout}
      style={{ width: "600px", marginLeft: "-60px" }}
    >
      <Form.Item label={TextObj.BAGINNING_DATE}>
        <DatePickerForm
          onChange={(e) =>
            isCompare
              ? handleCompareStartDateChange(e)
              : handleStartDateChange(e)
          }
          value={isCompare ? compareStart : start}
          isRange={isRange}
        />
      </Form.Item>
      {isRange && (
        <Form.Item label={TextObj.FINISH_DATE}>
          <DatePickerForm
            onChange={(e) =>
              isCompare ? handleCompareEndDateChange(e) : handleEndDateChange(e)
            }
            value={isCompare ? compareEnd : end}
            isRange={isRange}
          />
        </Form.Item>
      )}
      <Form.Item label={TextObj.PRIORITY_SEARCH}>
        <Selector
          onChange={isCompare ? handleCompareTypeChange : handleTypeChange}
          handleReset={() =>
            isCompare ? handleCompareTypeChange(null) : handleTypeChange(null)
          }
          value={isCompare ? compareType : type}
        />
      </Form.Item>
      <Form.Item label={TextObj.OS_SEARCH}>
        <DeviceSearch
          devicetype={isCompare ? compareDeviceType : deviceType}
          setDeviceType={
            isCompare ? handleChangeCompareDeviceType : handleChangeDeviceType
          }
        />
      </Form.Item>
      <Form.Item label={TextObj.USER_SEARCH}>
        <UserSelector skip={true} compare={isCompare} />
      </Form.Item>
    </Form>
  );
};

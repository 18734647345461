import { Col, Row, Table } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { LogColumns } from "../tableColumns";
import {
  setLogErrorStatus,
  setLogErrorType,
  setLogPage,
  setLogPageSize,
} from "../../reducers/log";
import { useEffect } from "react";
import { getAllLog } from "../../../actions/log/get-all-log.action";

export const LogTable = () => {
  const { logs, page, pageSize, allPage, errorType, errorStatus } = useSelector(
    (state) => state.logs
  );
  const dispatch = useDispatch();

  const handleTableChange = (pagination) => {
    dispatch(setLogPageSize(pagination.pageSize));
    dispatch(setLogPage(pagination.current));
  };

  const handleFilterType = (value) => {
    dispatch(setLogErrorType(value));
  };

  const handleFilterStatus = (value) => {
    dispatch(setLogErrorStatus(value));
  };

  useEffect(() => {
    const fetch = async () => {
      await getAllLog();
    };
    fetch();
  }, [page, pageSize, errorType, errorStatus]);

  return (
    <Row style={{ padding: "0.5em" }}>
      <Col span={24}>
        <Table
          style={{ width: "95%" }}
          bordered
          columns={Object.values(
            LogColumns({ handleFilterType, handleFilterStatus })
          )}
          dataSource={logs}
          pagination={{
            position: ["bottomCenter"],
            pageSize: pageSize,
            showSizeChanger: true,
            current: page,
            total: allPage * pageSize,
          }}
          onChange={handleTableChange}
          locale={{ emptyText: "Nincs adat" }}
          scroll={{ x: 2000 }}
        />
      </Col>
    </Row>
  );
};

import { useSelector } from "react-redux";
import React from "react";
import { PieCharts } from "./piecharts";

export const ComparePieCharts = () => {
  const { statistics, compareStatistic } = useSelector(
    (state) => state.statistic
  );

  return (
    <div className="compareContainer">
      <div className="left">
        <PieCharts data={statistics} compareData={compareStatistic} />
      </div>
      <div className="right">
        <PieCharts data={compareStatistic} compareData={statistics} />
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";

export const FailedCalculator = (props) => {
  const { statistics, type } = props;
  const [result, setResult] = useState(0);
  useEffect(() => {
    if (statistics) {
      setResult(
        statistics.filter((s) => s.type === type).map((data) => data.failed)
      );
    }
  }, [statistics, type]);
  return result;
};

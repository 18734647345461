import "./reset.css";
import "./App.css";
import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { router } from "./constants/router/router";
import { getMe } from "./actions/user/get-me";
import { store } from "./constants/store";

function App() {
  useEffect(() => {
    const func = async () => {
      await getMe();
    };
    func();
  }, []);
  return (
    <div>
      <React.StrictMode>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </React.StrictMode>
    </div>
  );
}

export default App;

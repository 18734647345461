import { useDispatch } from "react-redux";
import { MessagesForm } from "../form";
import { Modal } from "antd";
import { clearPushMessage } from "../../reducers/push-message";

export const MessagesModal = (props) => {
  const { showModal, setShowModal } = props;
  const dispatch = useDispatch();

  const handleCancel = () => {

    setShowModal(false);
    dispatch(clearPushMessage());
  };

  return (
    <Modal
      className="modal-body"
      open={showModal}
      title={"Létrehozás"}
      onCancel={handleCancel}
      footer={null}
      forceRender
    >
      <MessagesForm handleCancel={handleCancel} />
    </Modal>
  );
};

import {
  setPushMessageActionFinished,
  setPushMessageActionStart,
  setPushMessages,
} from "../../constants/reducers/push-message";
import { store } from "../../constants/store";
import { createCall } from "../base/base";
import { BASE_METHODS } from "../../constants/features/actionTypes";
export const getAllPushMessage = async () => {
  store.dispatch(setPushMessageActionStart());
  const { searchText } = store.getState().push;
  const queryParams = new URLSearchParams();
  if (searchText) queryParams.append("searchText", searchText);

  const dataToSend = {
    url: "message",
    method: BASE_METHODS.GET,
    contentType: "application/json",
    queryParams: queryParams,
  };
  const request = await createCall(dataToSend);
  try {
    const response = request;
    store.dispatch(setPushMessages(response.data.messages));
    store.dispatch(setPushMessageActionFinished());
    return response;
  } catch (error) {
    store.dispatch(setPushMessageActionFinished());
    return null;
  }
};

import { store } from "../../constants/store";
import { createCall } from "../base/base";
import { BASE_METHODS } from "../../constants/features/actionTypes";
import {
  setLogActionEnd,
  setLogActionStart,
  setLogs,
} from "../../constants/reducers/log";

export const getAllLog = async () => {
  store.dispatch(setLogActionStart());
  const { page, pageSize, errorType, errorStatus } = store.getState().logs;

  const queryParams = new URLSearchParams();
  queryParams.append("page", page ?? 1);
  queryParams.append("pageSize", pageSize ?? 10);
  if (errorType !== "") queryParams.append("errorType", errorType);
  if (errorStatus !== "") queryParams.append("errorStatus", errorStatus);

  const dataToSend = {
    url: "log",
    method: BASE_METHODS.GET,
    contentType: "application/json",
    body: undefined,
    queryParams,
  };

  const request = await createCall(dataToSend);
  try {
    const response = request;
    store.dispatch(setLogs(response.data));
    store.dispatch(setLogActionEnd());
    return response;
  } catch (error) {
    store.dispatch(setLogActionEnd());
    return null;
  }
};

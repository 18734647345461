import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetUser,
  setEmail,
  setPassword,
  setPasswordCheck,
} from "../../constants/reducers/user";
import { modifyProfile } from "../../actions/profile";
import { Col, Form, Row, Input, Button } from "antd";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 4,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};

export const Profile = () => {
  const formRef = React.createRef();
  const dispatch = useDispatch();
  const { authenticatedUser } = useSelector((state) => state.login);
  const { email, password, passwordCheck } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(resetUser());
  }, [dispatch]);
  
  const handleChangeEmail = useCallback(
    (value) => {
      dispatch(setEmail(value));
    },
    [dispatch]
  );

  const handleChangePassword = useCallback(
    (value) => {
      dispatch(setPassword(value));
    },
    [dispatch]
  );

  const handleChangePasswordCheck = useCallback(
    (value) => {
      dispatch(setPasswordCheck(value));
    },
    [dispatch]
  );

  const handleSave = async () => {
    await modifyProfile();
  };

  return (
    <div style={{ marginLeft: "300px" }}>
      <Row>
        <Col span={18} style={{ padding: "1em" }}>
          <h1 style={{ fontSize: "2rem" }}>
            <strong>Profil</strong>
          </h1>
          <Form
            ref={formRef}
            {...formItemLayout}
            name="register"
            onFinish={handleSave}
            scrollToFirstError
          >
            <Form.Item
              name="email"
              label={"E-mail cím"}
              rules={[
                {
                  type: "email",
                  message: "Helytelen formátum!",
                },
                {
                  required: true,
                  message: "Kérem adja meg az e-mail címét!",
                },
              ]}
            >
              <Input
                style={{ width: "20em" }}
                name="email"
                value={email}
                placeholder={authenticatedUser.email}
                onChange={(e) => handleChangeEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label={"Jelszó"}
              rules={[
                {
                  required: false,
                  message: "Kérem adja meg a jelszavát!",
                },
              ]}
            >
              <Input.Password
                style={{ width: "20em" }}
                name="password"
                value={password}
                onChange={(e) => handleChangePassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label={"Jelszó megerősítés"}
              name="confirm"
              dependencies={["password"]}
              rules={[
                {
                  required: password.length > 0,
                  message: "Kérem erősítse meg jelszavát!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("A megadott jelszavak nem egyeznek!");
                  },
                }),
              ]}
            >
              <Input.Password
                style={{ width: "20em" }}
                name="confirm"
                type="password"
                value={passwordCheck}
                onChange={(e) => handleChangePasswordCheck(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button
                style={{
                  display: "flex",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
                htmlType="submit"
                type="primary"
                disabled={!email || !password || !(password === passwordCheck)}
              >
                Mentés
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

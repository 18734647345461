import { Select } from "antd";
import React from "react";
import { PriorityOptions } from "../utils";
export const Selector = (props) => {
  const { onChange, handleReset, value } = props;

  return (
    <Select
      allowClear
      options={PriorityOptions}
      style={{ width: "100%" }}
      onChange={onChange}
      placeholder={"Összes"}
      onClear={handleReset}
      value={value}
    />
  );
};

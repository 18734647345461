import { message } from "antd";
import {
  clearPushMessage,
  deleteMessage,
  setPushMessageActionFinished,
  setPushMessageActionStart,
} from "../../constants/reducers/push-message";
import { store } from "../../constants/store";
import { createCall } from "../base/base";
import { BASE_METHODS } from "../../constants/features/actionTypes";

export const deletePushMessage = async (id) => {
  store.dispatch(setPushMessageActionStart());

  const dataToSend = {
    url: `message/${id}`,
    method: BASE_METHODS.DELETE,
    contentType: "application/json",
  };
  const request = await createCall(dataToSend);
  try {
    store.dispatch(deleteMessage(id));
    store.dispatch(clearPushMessage());
    store.dispatch(setPushMessageActionFinished());
    message.success("Sikeres törlés");
    return request;
  } catch (error) {
    store.dispatch(setPushMessageActionFinished());
    return;
  }
};

import { Button } from "antd";

export const CustomButton = (props) => {
  const { type, htmlType, key, disabled, buttonText, onClick } = props;

  return (
    <Button
      type={type}
      htmlType={htmlType}
      key={key}
      disabled={disabled}
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );
};

import { Button, Popconfirm, Space } from "antd";
import { useMemo } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { deletePushMessage } from "../../../actions/pushMessage/delete-push-message";
import { TextObj } from "../../text";
export const MessagesColumns = () => {
  const popupConfirm = async (record) => {
    await deletePushMessage(record.id);
  };

  return useMemo(() => {
    return [
      {
        title: TextObj.MESSAGES_TITLE,
        dataIndex: TextObj.TITLE,
        align: "left",
        width: "400px",
      },
      {
        title: TextObj.MESSAGES_MESSAGE_LABEL,
        dataIndex: TextObj.MESSAGES_MESSAGE,
        align: "left",
        ellipsis: true,
      },
      {
        title: TextObj.MESSAGES_CREATED_LABEL,
        dataIndex: TextObj.MESSAGES_CREATED,
        render: (string, data) => {
          return (
            <Space size="small">
              {new Date(data.created).toLocaleDateString()}
            </Space>
          );
        },
        sorter: (a, b) =>
          new Date(a.created).getTime() - new Date(b.created).getTime(),
        align: "right",
        width: "150px",
      },
      {
        title: TextObj.PRIORITY,
        dataIndex: TextObj.MESSAGEID,
        render: (string, data) => {
          let res = "Személyes";
          switch (data.messageId[0]) {
            case "2":
              res = TextObj.FIRST_PRIO;
              break;
            case "3":
              res = TextObj.SECOND_PRIO;
              break;
            case "5":
              res = TextObj.THIRD_PRIO;
              break;
            case "1":
              res = TextObj.FOURTH_PRIO;
              break;
            default:
              break;
          }
          return res;
        },
        width: "100px",
      },
      {
        title: TextObj.ITERATION,
        dataIndex: TextObj.MESSAGEID,
        render: (string, data) => {
          let res = "Egyszeri";
          console.log(data.messageId[2]);
          switch (data.messageId[2]) {
            case "1":
              res = TextObj.DAILY_TYPE;
              break;
            case "2":
              res = TextObj.SECOND_DAY_TYPE;
              break;
            case "3":
              res = TextObj.THIRD_DAY_TYPE;
              break;
            case "7":
              res = TextObj.WEEKLY_TYPE;
              break;
            case "4":
              res = TextObj.SECOND_WEEK_TYPE;
              break;
            default:
              break;
          }
          switch (data.messageId[1]) {
            case "3":
              res = TextObj.MONTHLY_TYPE;
              break;
            case "6":
              res = TextObj.SECOND_MONTH_TYPE;
              break;
            case "9":
              res = TextObj.THIRD_MONTH_TYPE;
              break;
            default:
              break;
          }
          return res;
        },
        width: "150px",
      },
      {
        title: "",
        key: TextObj.DELETE,
        render: (string, record) => (
          <Popconfirm
            title={TextObj.MESSAGES_POPUP_CONFIRM_TEXT}
            onConfirm={() => popupConfirm(record)}
            okText={TextObj.YES}
            cancelText={TextObj.NO}
          >
            <Button className="button" type="primary" danger>
              <DeleteOutlined />
              {TextObj.DELETE_LABEL}
            </Button>
          </Popconfirm>
        ),
        width: "120px",
      },
    ];
  }, []);
};

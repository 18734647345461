import React from "react";
import "./style.css";
import { Mix } from "@ant-design/plots";
import { TextObj } from "../../text";

export const PieChart = (props) => {
  const {
    data,
    title,
    maxStatType,
    hideStat,
    dailyStat,
    twoDayStat,
    threeDayStat,
    weeklyStat,
    twoWeeklyStat,
    monthlyStat,
    twoMonthlyStat,
    quarterStat,
    dailyFailed,
    twoDayFailed,
    threeDayFailed,
    weeklyFailed,
    twoWeeklyFailed,
    monthlyFailed,
    twoMonthlyFailed,
    quarterFailed,
  } = props;

  if (data.length === 0) {
    return null;
  }

  const config = {
    tooltip: false,
    plots: [
      {
        type: "pie",
        region: {
          start: {
            x: 0,
            y: 0,
          },
          end: {
            x: 0.5,
            y: 0.9,
          },
        },
        options: {
          data: data,
          angleField: "count",
          colorField: "type",
          tooltip: {
            showMarkers: false,
          },
          radius: 0.85,
          interactions: [
            {
              type: "element-highlight",
            },
            {
              type: "custom-association-filter",
            },
          ],
        },
      },
      {
        type: "area",
        region: {
          start: {
            x: 0.5,
            y: 0.2,
          },
          end: {
            x: 0.8,
            y: 0.8,
          },
        },
        options: {
          data: data,
          xField: "type",
          yField: "megnyitva",
          seriesField: "megnyitva",
          line: {},
          point: {
            style: {
              r: 2.5,
            },
          },
          meta: {
            time: {
              range: [0, 1],
            },
          },
          smooth: true,
          tooltip: {
            showCrosshairs: true,
            shared: true,
          },
        },
      },
    ],
  };
  return (
    <div>
      <div>
        <h1 className="title">
          <strong>{title}</strong>
        </h1>
      </div>
      <Mix {...config} />
      <ul>
        <li>{`${TextObj.DAILY_OPEN} ${dailyStat}`}</li>
        {!hideStat.includes(2) && (
          <li>{`${TextObj.SECOND_DAY_OPEN} ${twoDayStat}`}</li>
        )}
        {!hideStat.includes(3) && (
          <li>{`${TextObj.THIRD_DAY_OPEN} ${threeDayStat}`}</li>
        )}
        {!hideStat.includes(7) && (
          <li>{`${TextObj.WEEKLY_OPEN} ${weeklyStat}`}</li>
        )}
        {!hideStat.includes(14) && (
          <li>{`${TextObj.SECOND_WEEK_OPEN} ${twoWeeklyStat}`}</li>
        )}
        {!hideStat.includes(30) && (
          <li>{`${TextObj.MONTHLY_OPEN} ${monthlyStat}`}</li>
        )}
        {maxStatType === 60 && (
          <li>{`${TextObj.SECOND_MONTH_OPEN} ${twoMonthlyStat}`}</li>
        )}
        {maxStatType === 90 && (
          <li>{`${TextObj.QUARTER_YEAR_OPEN} ${quarterStat}`}</li>
        )}
        <div className="separate"></div>
        <li>{`${TextObj.DAILY_FAILED} ${dailyFailed}`}</li>
        {!hideStat.includes(2) && (
          <li>{`${TextObj.SECOND_DAY_FAILED} ${twoDayFailed}`}</li>
        )}
        {!hideStat.includes(3) && (
          <li>{`${TextObj.THIRD_DAY_FAILED} ${threeDayFailed}`}</li>
        )}
        {!hideStat.includes(7) && (
          <li>{`${TextObj.WEEKLY_FAILED} ${weeklyFailed}`}</li>
        )}
        {!hideStat.includes(14) && (
          <li>{`${TextObj.SECOND_WEEK_FAILED} ${twoWeeklyFailed}`}</li>
        )}
        {!hideStat.includes(30) && (
          <li>{`${TextObj.MONTHLY_FAILED} ${monthlyFailed}`}</li>
        )}
        {maxStatType === 60 && (
          <li>{`${TextObj.SECOND_MONTH_FAILED} ${twoMonthlyFailed}`}</li>
        )}
        {maxStatType === 90 && (
          <li>{`${TextObj.QUARTER_YEAR_FAILED} ${quarterFailed}`}</li>
        )}
      </ul>
    </div>
  );
};

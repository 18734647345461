import axios from "axios";
import { store } from "../../constants/store";
import { message } from "antd";
import { TextObj } from "../../constants/text";
import { getMeFailure } from "../../constants/reducers/login";
import { requestStart } from "../../constants/reducers/base";
export const createCall = async (props) => {
  const { url, method, contentType, body, queryParams, skip } = props;
  if (url !== "users/me") {
    store.dispatch(requestStart());
  }
  const { token } = store.getState().login;
  if (!skip) {
    if (token === null) {
      store.dispatch(getMeFailure());
    }
  }

  try {
    const response = await axios({
      method: method,
      url: `${process.env.REACT_APP_BASE_URL}/api/1.0.0/${url}${
        queryParams ? "?" + queryParams : ""
      }`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": contentType,
      },
      data: body,
    }).then((response) => {
      return response;
    });

    return response;
  } catch (error) {
    if (error.response && error.response.data) {
      let errorMessage = "";
      switch (error.response.data.code) {
        case 1:
          errorMessage = TextObj.SERVER_TIMEOUT_ERROR_TEXT;
          break;
        case 10:
          errorMessage = TextObj.INVALID_PARAMETERS;
          break;
        case 20 || 30:
          errorMessage = TextObj.NO_RIGHTS;
          break;
        default:
          break;
      }
      !window.location.href.includes("/login") ?? message.error(errorMessage);
    }
    return error;
  }
};

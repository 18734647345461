import { Col, Row, Table } from "antd";
import { useSelector } from "react-redux";
import { MessagesColumns } from "../tableColumns";

export const MessagesTable = () => {
  const { messages } = useSelector((state) => state.push);

  return (
    <Row style={{ padding: "0.5em" }}>
      <Col span={24}>
        <Table
          bordered
          columns={Object.values(MessagesColumns())}
          dataSource={messages}
          pagination={{
            position: ["bottomCenter"],
            pageSize: 10,
            showSizeChanger: false,
          }}
          locale={{ emptyText: "Nincs adat" }}
          style={{ width: "95%" }}
        />
      </Col>
    </Row>
  );
};

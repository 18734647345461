import { store } from "../../constants/store";
import { createCall } from "../base/base";
import { BASE_METHODS } from "../../constants/features/actionTypes";
import {
  deleteUser,
  setUserActionEnd,
  setUserActionStart,
} from "../../constants/reducers/user";

export const deleteUserAction = async () => {
  store.dispatch(setUserActionStart());
  const { selectedId } = store.getState().user;

  const dataToSend = {
    url: `users/${selectedId}`,
    method: BASE_METHODS.DELETE,
    contentType: "application/json",
  };

  const request = await createCall(dataToSend);
  try {
    if (request.data) {
      store.dispatch(deleteUser());
      store.dispatch(setUserActionEnd());
    }
  } catch (error) {
    store.dispatch(setUserActionEnd());
    return;
  }
};

import { Col, Row } from "antd";
import React, { useState } from "react";
import { PlusCircleFilled } from "@ant-design/icons";
import { AdminTable } from "../../constants/features/table";
import { AdminModal } from "../../constants/features/modal";
import { TextObj } from "../../constants/text";
import { CustomButton } from "../../constants/features/button";

export const Admins = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  return (
    <div
      style={{
        paddingLeft: "300px",
        top: 0,
        paddingTop: "80px",
      }}
    >
      <Row style={{ padding: "0.5em" }}>
        <Col span={24}>
          <CustomButton
            type={TextObj.PRIMARY_BUTTON_TYPE}
            onClick={handleOpenModal}
            buttonText={
              <>
                <PlusCircleFilled /> {TextObj.NEW_USER}
              </>
            }
          />
        </Col>
      </Row>
      <AdminTable />
      <div className="modal">
        <AdminModal showModal={showModal} setShowModal={setShowModal} />
      </div>
    </div>
  );
};

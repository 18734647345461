import { message } from "antd";
import { store } from "../../constants/store";
import { createCall } from "../base/base";
import { TextObj } from "../../constants/text";
import { BASE_METHODS } from "../../constants/features/actionTypes";
import {
  setUserActionEnd,
  setUserActionStart,
} from "../../constants/reducers/user";
export const modifyProfile = async () => {
  store.dispatch(setUserActionStart());
  const { email, password } = store.getState().user;
  const params = {
    email,
    password,
  };
  const dataToSend = {
    url: "users/modify-user-profile",
    method: BASE_METHODS.POST,
    contentType: "application/json",
    body: params,
  };
  const request = await createCall(dataToSend);
  try {
    const response = request;
    store.dispatch(setUserActionEnd());
    return response;
  } catch (error) {
    const responseError = request;
    const errorResponeData = responseError.response?.data;
    if (errorResponeData) {
      let errorMessage = "";
      switch (errorResponeData.code) {
        case 1003:
          errorMessage = TextObj.CREATE_USER_ERROR;
          break;
        default:
          break;
      }
      message.error(errorMessage);
      store.dispatch(setUserActionEnd());
      return;
    }
    store.dispatch(setUserActionEnd());
    return;
  }
};

import { useCallback } from "react";
import { Button, Form, Space } from "antd";
import {
  formItemLayout,
  tailFormItemLayout,
} from "../formItemLayout";
import { useDispatch, useSelector } from "react-redux";
import { SaveFilled } from "@ant-design/icons";
import { setEmail, setPassword, setPasswordCheck } from "../../reducers/user";
import { createUserAction } from "../../../actions/user/create-user";
import { TextObj } from "../../text";
import { TextInput } from "../input";
import { CustomButton } from "../button";
export const AdminForm = (props) => {
  const dispatch = useDispatch();
  const { email, password, passwordCheck } = useSelector((state) => state.user);
  const { handleCancel } = props;
  const handleChangeEmail = useCallback(
    (event) => {
      dispatch(setEmail(event));
    },
    [dispatch]
  );

  const handleChangePassword = useCallback(
    (event) => {
      dispatch(setPassword(event));
    },
    [dispatch]
  );

  const handleChangePasswordCheck = useCallback(
    (event) => {
      dispatch(setPasswordCheck(event));
    },
    [dispatch]
  );
  const handleSave = async () => {
    await createUserAction();
    handleCancel();
  };

  return (
    <Form
      className="modal-body"
      name="register"
      {...formItemLayout}
      scrollToFirstError
    >
      <Form.Item label={TextObj.EMAIL} hasFeedback>
        <TextInput
          value={email}
          name={TextObj.FORM_EMAIL_NAME}
          onChange={(e) => handleChangeEmail(e.target.value)}
          validator={/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/}
          errorMessage={TextObj.EMAIL_INVALID_ERROR}
          type={TextObj.TEXT_INPUT_TYPE}
        />
      </Form.Item>
      <Form.Item label={TextObj.PASSWORD} hasFeedback>
        <TextInput
          value={password}
          name={TextObj.FORM_PASSWORD_NAME}
          onChange={(e) => handleChangePassword(e.target.value)}
          validator={/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/}
          errorMessage={TextObj.PASSWORD_INVALID_ERROR}
          type={TextObj.FORM_PASSWORD_NAME}
        />
      </Form.Item>
      <Form.Item label={TextObj.PASSWORD_CHECK} hasFeedback>
        <TextInput
          value={passwordCheck}
          name={TextObj.PASSWORD_CHECK}
          onChange={(e) => handleChangePasswordCheck(e.target.value)}
          errorMessage={TextObj.PASSWORD_CHECK_INVALID_ERROR}
          type={TextObj.FORM_PASSWORD_NAME}
        />
        {passwordCheck?.length > 0 && !(password === passwordCheck) && (
          <span style={{ color: "red" }}>
            {TextObj.PASSWORD_CHECK_INVALID_ERROR}
          </span>
        )}
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Space>
          <CustomButton
            type={TextObj.PRIMARY_BUTTON_TYPE}
            htmlType={TextObj.SUBMIT_HTML_BUTTON_TYPE}
            key={TextObj.SAVE_BUTTON_KEY}
            disabled={!email || !password || !(password === passwordCheck)}
            onClick={handleSave}
            buttonText={
              <>
                <SaveFilled /> {TextObj.SAVE}
              </>
            }
          />

          <Button key={TextObj.CANCEL_BUTTON_KEY} onClick={handleCancel}>
            {TextObj.CANCEL}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

import { store } from "../../constants/store";
import { createCall } from "../base/base";
import { BASE_METHODS } from "../../constants/features/actionTypes";
import {
  setUserActionEnd,
  setUserActionStart,
  setUsers,
} from "../../constants/reducers/user";
export const getAllUser = async () => {
  store.dispatch(setUserActionStart());
  const dataToSend = {
    url: "users",
    method: BASE_METHODS.GET,
    contentType: "application/json",
  };

  const request = await createCall(dataToSend);
  try {
    const response = request;
    store.dispatch(setUsers(response.data.users));
    store.dispatch(setUserActionEnd());
    return response;
  } catch (error) {
    store.dispatch(setUserActionEnd());
    return null;
  }
};

import { store } from "../../constants/store";
import { createCall } from "../base/base";
import { BASE_METHODS } from "../../constants/features/actionTypes";
import {
  setUserActionEnd,
  setUserActionStart,
} from "../../constants/reducers/user";
import { getMeFailure, getMeOk } from "../../constants/reducers/login";

export const getMe = async () => {
  store.dispatch(setUserActionStart());
  const dataToSend = {
    url: "users/me",
    method: BASE_METHODS.GET,
    contentType: "application/json",
  };
  const request = await createCall(dataToSend);
  if (request?.data) {
    store.dispatch(getMeOk(request.data));
    store.dispatch(setUserActionEnd());
  } else {
    store.dispatch(getMeFailure());
    store.dispatch(setUserActionEnd());
    return;
  }
};

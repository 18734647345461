import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  dictionaryUsersActionStart: false,
};

export const distionaryUsersSlice = createSlice({
  name: "distionaryUsers",
  initialState,
  reducers: {
    setDistionaryUsersActionStart: (state) => {
      state.dictionaryUsersActionStart = true;
    },
    setDictionaryUsers: (state, action) => {
      state.users = action.payload;
    },
    setDistionaryUsersActionFinish: (state) => {
      state.dictionaryUsersActionStart = false;
    },
  },
});

export const {
  setDistionaryUsersActionStart,
  setDictionaryUsers,
  setDistionaryUsersActionFinish,
} = distionaryUsersSlice.actions;

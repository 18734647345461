import { getAllStatistic } from "../../actions/statistic";
import { getAllDictionaryUser } from "../../actions/user/get-all-distionary-users";
import { resetStatistics } from "../reducers/statistic";
import { store } from "../store";

export const statisticLoader = async (args) => {
  store.dispatch(resetStatistics());
  await getAllDictionaryUser();
  return await getAllStatistic();
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  statisticActionStart: false,
  statistics: [],
  compareStatistic: [],
  start: null,
  end: null,
  type: null,
  compareStart: null,
  compareEnd: null,
  compareType: null,
  deviceType: null,
  compareDeviceType: null,
  userId: null,
  userEmail: null,
  compareUserEmail: null,
};

export const statisticSlice = createSlice({
  name: "statistic",
  initialState,
  reducers: {
    setStatActionStart: (state) => {
      state.statisticActionStart = true;
    },
    setStatistics: (state, action) => {
      state.statistics = action.payload;
    },
    setCompareStatistic: (state, action) => {
      state.compareStatistic = action.payload;
    },
    setStatisticStart: (state, action) => {
      state.start = action.payload;
    },
    setStatisticEnd: (state, action) => {
      state.end = action.payload;
    },
    setStatisticType: (state, action) => {
      state.type = action.payload;
    },
    setStatisticCompareStart: (state, action) => {
      state.compareStart = action.payload;
    },
    setStatisticCompareEnd: (state, action) => {
      state.compareEnd = action.payload;
    },
    setStatisticCompareType: (state, action) => {
      state.compareType = action.payload;
    },
    setStatActionFinished: (state) => {
      state.statisticActionStart = false;
    },
    setDeviceType: (state, action) => {
      state.deviceType = action.payload;
    },
    setCompareDeviceType: (state, action) => {
      state.compareDeviceType = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setCompareUserEmail: (state, action) => {
      state.compareUserEmail = action.payload;
    },
    removeUserEmail: (state) => {
      state.userEmail = null;
    },
    removeCompareUserEmail: (state) => {
      state.compareUserEmail = null;
    },
    resetStatistics: (state) => {
      state.start = null;
      state.end = null;
      state.type = null;
      state.compareStart = null;
      state.compareEnd = null;
      state.compareType = null;
      state.deviceType = null;
      state.compareDeviceType = null;
      state.userId = null;
      state.userEmail = null;
      state.compareUserEmail = null;
    },
  },
});

export const {
  setStatActionStart,
  setStatistics,
  setCompareStatistic,
  setStatActionFinished,
  setStatisticStart,
  setStatisticEnd,
  setStatisticType,
  setStatisticCompareStart,
  setStatisticCompareEnd,
  setStatisticCompareType,
  resetStatistics,
  setDeviceType,
  setCompareDeviceType,
  setUserId,
  setUserEmail,
  setCompareUserEmail,
  removeUserEmail,
  removeCompareUserEmail
} = statisticSlice.actions;

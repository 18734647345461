import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { TextObj } from "../../text";
import { removeUsers, selectUsers } from "../../reducers/push-message";
import {
  removeCompareUserEmail,
  removeUserEmail,
  setCompareUserEmail,
  setUserEmail,
} from "../../reducers/statistic";

export const UserSelector = (props) => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.distionaryUsers);
  const { userIds } = useSelector((state) => state.push);
  const { userEmail, compareUserEmail } = useSelector(
    (state) => state.statistic
  );
  const { skip, type, compare } = props;
  const handleSelectedItemChange = (selectedItems) => {
    type === "multiple"
      ? dispatch(selectUsers(selectedItems[selectedItems.length - 1]))
      : compare
      ? dispatch(setCompareUserEmail(selectedItems))
      : dispatch(setUserEmail(selectedItems));
  };

  const handleRemoveUsers = (selectedItem) => {
    type === "multiple"
      ? dispatch(removeUsers(selectedItem))
      : compare
      ? dispatch(removeCompareUserEmail())
      : dispatch(removeUserEmail());
  };

  const filterUserOptions = (inputValue, option) => {
    return option.children.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <div>
      <Select
        mode={type}
        value={
          type === "multiple" ? userIds : compare ? compareUserEmail : userEmail
        }
        onChange={handleSelectedItemChange}
        onDeselect={handleRemoveUsers}
        filterOption={filterUserOptions}
        style={{ width: "100%" }}
        showSearch={true}
        allowClear={type === "multiple" ? false : true}
      >
        {users?.map((item) => (
          <Select.Option
            key={item.szotarId}
            value={type === "multiple" ? item.szotarId : item.email}
          >
            {item.email}
          </Select.Option>
        ))}
      </Select>
      {userIds.length === 0 && !skip && (
        <span style={{ color: "red" }}>{TextObj.MESSAGE_SEELCTOR}</span>
      )}
    </div>
  );
};

export const errorTypes = [
  { title: "api guard no api key", status: "NO API KEY IS PRESENT IN REQUEST" },
  {
    title: "api guard invalid api key",
    status: "INVALID API KEY IS PRESENT IN REQUEST",
  },
  { title: "ROLE ERROR", status: "ROLE ERROR" },
  { title: "unexpected", status: "UNEXPECTED ERROR" },
  { title: "Általános", status: "API EXCEPTION ERROR" },
  { title: "forbidden", status: "FORBIDDEN ERROR" },
  { title: "unauthorized", status: "UNAUTHORIZED ERROR" },
  { title: "ak sent data", status: "AK SENT DATA" },
  { title: "ak error sent data", status: "AK ERROR SENT DATA" },
  { title: "ak error response data", status: "AK ERROR RESPONSE DATA" },
  { title: "refresh user", status: "REFRESH USER ERROR" },
  { title: "delete user", status: "DELETE USER ERROR" },
  { title: "modify user with api", status: "MODIFY USER VIA API ERROR" },
  {
    title: "connect institution with user",
    status: "CONNECT INSTITUTION WITH USER ERROR",
  },
  { title: "subscribe for email", status: "SUBSCRIBE FOR EMAIL ERROR" },
  { title: "never wanted push", status: "NEVER WANTED PUSH ERROR" },
  { title: "predictive", status: "PREDICTIVE SERACH ERROR" },
  { title: "search", status: "SERACH WORD ERROR" },
  { title: "search article", status: "SERACH ARTICLE ERROR" },
  { title: "add dictionary to custom search", status: "DICTIONARY ADD ERROR" },
  {
    title: "activate dictionary / boundle",
    status: "DICTIONARY / BOUNDLE ACTIVATION ERROR",
  },
  {
    title: "buy dictionary bundle",
    status: "BUY DICTIONARY BUNDLE ERROR",
  },
  { title: "check renewable subs", status: "PURCHASE NOT FOUND ERROR" },
  { title: "get favorites", status: "GET FAVORITES ERROR" },
  { title: "add favorites", status: "ADD FAVORITES ERROR" },
  { title: "remove from favorites", status: "REMOVE FROM FAVORITES ERROR" },
  {
    title: "remove all from favorites",
    status: "REMOVE ALL FROM FAVORITES ERROR",
  },
  { title: "get history", status: "GET HISTORY ERROR" },
  { title: "add history", status: "ADD HISTORY ERROR" },
  { title: "delete history", status: "DELETE HISTORY ERROR" },
  { title: "delete all history", status: "DELETE ALL HISTORY ERROR" },
  { title: "Push üzenet", status: "ERROR" },
  {
    title: "delete message from user",
    status: "DELETE MESSAGE FROM USER ERROR",
  },
  {
    title: "get message by user",
    status: "GET MESSAGE BY USER ERROR",
  },
  { title: "SHOW POPUP", status: "SHOW POPUP ERROR" },
  { title: "belepes", status: "ERROR" },
  { title: "regisztracio", status: "ERROR" },
  { title: "usr", status: "ERROR" },
  { title: "usrtorles", status: "ERROR" },
  { title: "usradatmodositas", status: "ERROR" },
  { title: "jelszoemlekezteto", status: "ERROR" },
  { title: "apiadat", status: "ERROR" },
  { title: "usrintegy", status: "ERROR" },
  { title: "usrhirlevel", status: "ERROR" },
  { title: "prediktiv", status: "ERROR" },
  { title: "keres", status: "ERROR" },
  { title: "szocikk", status: "ERROR" },
  { title: "szocikkadat", status: "ERROR" },
  { title: "usrszotarkeres", status: "ERROR" },
  { title: "szotar", status: "ERROR" },
  { title: "elofizetesek", status: "ERROR" },
  { title: "csomagok", status: "ERROR" },
  { title: "csomag", status: "ERROR" },
  { title: "usrszotartrial", status: "ERROR" },
  { title: "aktivalas", status: "ERROR" },
  { title: "usrelofizetes", status: "ERROR" },
  { title: "webdokumentum", status: "ERROR" },
  { title: "usrkedvenc", status: "ERROR" },
  { title: "usrkedvencfelvetel", status: "ERROR" },
  { title: "usrkedvenctorles", status: "ERROR" },
  { title: "usrkedvenctorlesosszes", status: "ERROR" },
  { title: "usrelozmeny", status: "ERROR" },
  { title: "usrelozmenyfelvetel", status: "ERROR" },
  { title: "usrelozmenytorles", status: "ERROR" },
  { title: "usrelozmenytorlesosszes", status: "ERROR" },
  { title: "jwt-mobile info", status: "JWT-MOBILE-INFO" },
  { title: "jwt-mobile error", status: "JWT-MOBILE-ERROR" },
  {
    title: "buy dictionary bundle cert error",
    status: "DICTIONARY BUNDLE CERT ERROR",
  },
  { title: "buy dictionary bundle", status: "VERIFY DICTIONARY BUNDLE ERROR" },
  {
    title: "check renewable subscriptions cert",
    status: "CHECK RENEWABLE SUBS CERT ERROR",
  },
  {
    title: "check renewable subscriptions",
    status: "VERIFY CHECK RENEWABLE SUBS ERROR",
  },
  {
    title: "buy dictionary bundle params",
    status: "BUY DICTIONARY BUNDLE PARAMS",
  },
  {
    title: "check renewable subs params",
    status: "CHECK RENEWABLE SUBS PARAMS",
  },
  { title: "success", status: "success" },
  { title: "subscribe device", status: "SUBSCRIBE DEVICE PARAMS" },
  { title: "unsubscribe device", status: "UNSUBSCRIBE DEVICE PARAMS" },
  { title: "login mobile", status: "LOGIN MOBILE PARAMS" },
  { title: "auth mobile EDUID", status: "AUTH MOBILE EDUID PARAMS" },
  {
    title: "register normal user to api",
    status: "REG NORMAL USER TO API PARAMS",
  },
  { title: "authenticate mobile", status: "AUTHENTICATE MOBILE PARAMS" },
  { title: "auth mobile IP", status: "AUTH MOBILE IP PARAMS" },
  {
    title: "refresh user with login data",
    status: "REFRESH USER WITH LOGIN DATA PARAMS",
  },
  { title: "refresh user params", status: "REFRESH USER PARAMS" },
  { title: "delete user from API", status: "DELETE USER FROM API PARAMS" },
  { title: "login to API", status: "LOGIN TO API PARAMS" },
  { title: "login to API normal", status: "LOGIN TO API NORMAL PARAMS" },
  { title: "login to API EDUID", status: "LOGIN TO API EDUID PARAMS" },
  { title: "login to API IP", status: "LOGIN TO API IP PARAMS" },
  { title: "login to API facebook", status: "LOGIN TO API FACEBOOK PARAMS" },
  { title: "login to API apple", status: "LOGIN TO API APPLE PARAMS" },
  { title: "login to API google", status: "LOGIN TO API GOOGLE PARAMS" },
  { title: "login to API mobile", status: "LOGIN TO API MOBILE PARAMS" },
  { title: "get user data from API", status: "GET USER DATA FROM API PARAMS" },
  { title: "modify user with API", status: "MODIFY USER WITH API PARAMS" },
  {
    title: "forgot password from API",
    status: "FORGOT PASSWORD FROM API PARAMS",
  },
  { title: "get API data", status: "GET API DATA PARAMS" },
  {
    title: "find or create facebook",
    status: "FIND OR CREATE FACEBOOK PARAMS",
  },
  {
    title: "find or create google",
    status: "FIND OR CREATE GOOGLE PARAMS",
  },
  {
    title: "find or create apple",
    status: "FIND OR CREATE APPLE PARAMS",
  },
  {
    title: "check if subs are valid",
    status: "CHECK IF SUBS ARE VALID PARAMS",
  },
  {
    title: "connect institution with user params",
    status: "CONNECT INSTITUTION WITH USER PARAMS",
  },
  { title: "subscribe for email", status: "SUBSCRIBE FOR EMAIL PARAMS" },
  { title: "never wanted push", status: "NEVER WANTED PUSH PARAMS" },
  { title: "predictive params", status: "PREDICTIVE SEARCH PARAMS" },
  { title: "search params", status: "SEARCH WORD PARAMS" },
  { title: "search article params", status: "SEARCH ARTICLE PARAMS" },
  { title: "search article data params", status: "SEARCH ARTICLE DATA PARAMS" },
  {
    title: "add dictionary to custom search params",
    status: "DICTIONARY ADD PARAMS",
  },
  { title: "get dictionary data", status: "GET DICTIONARY DATA PARAMS" },
  { title: "get subscriptions", status: "GET SUBSCRIPTIONS PARAMS" },
  { title: "get boundles with data", status: "GET BOUNDLES WITH DATA PARAMS" },
  { title: "get boundles", status: "GET BOUNDLES PARAMS" },
  { title: "get boundle data", status: "GET BOUNDLE DATA PARAMS" },
  { title: "start dictionary trial", status: "START DICTIONARY TRIAL PARAMS" },
  {
    title: "activate dictionary or boundle",
    status: "ACTIVATE DICTIONARY OR BOUNDLE PARAMS",
  },
  { title: "buy dictionary bundle", status: "BUY DICTIONARY BUNDLE PARAMS" },
  { title: "acknowledge purchase", status: "ACKNOWLEDGE PURCHASE PARAMS" },
  { title: "get web documents", status: "GET WEB DOCUMENTS PARAMS" },
  { title: "get favorites params", status: "GET FAVORITES PARAMS" },
  { title: "add to favorites params", status: "ADD TO FAVORITES PARAMS" },
  {
    title: "remove form favorites params",
    status: "REMOVE FROM FAVORITES PARAMS",
  },
  {
    title: "remove all form favorites params",
    status: "REMOVE ALL FROM FAVORITES PARAMS",
  },
  { title: "add history params", status: "ADD HISTORY PARAMS" },
  { title: "delete history params", status: "DELETE HISTORY PARAMS" },
  { title: "delete all history params", status: "DELETE ALL HISTORY PARAMS" },
  {
    title: "delete message from user params",
    status: "DELETE MESSAGE FROM USER PARAMS",
  },
  { title: "show popup params", status: "SHOW POPUP PARAMS" },
  { title: "FORBIDDEN ROLE ERROR", status: "FORBIDDEN ROLE ERROR" },
  { title: "social login", status: "SOCIAL LOGIN" },
];

import React, { useEffect, useState } from "react";
import "./style.css";
import { useSelector } from "react-redux";
import { Space } from "antd";
import {
  getAllStatistic,
  getAllCompareStatistic,
} from "../../actions/statistic";
import {
  ComparePieCharts,
  PieCharts,
  CheckBox,
  RadioGroupButtons,
} from "../../constants/features/items";
import { StatisticForm } from "../../constants/features/form";
import { TextObj } from "../../constants/text";

export const Statistic = () => {
  const [isRange, setIsRange] = useState(false);
  const [isCompare, setIsCompare] = useState(false);
  const [decider, setDecider] = useState(false);
  const [requiredData, setRequiredData] = useState("");
  const {
    start,
    end,
    type,
    compareStart,
    compareEnd,
    compareType,
    deviceType,
    compareDeviceType,
    userEmail,
    compareUserEmail,
    statistics,
  } = useSelector((state) => state.statistic);

  useEffect(() => {
    const fetch = async () => {
      if (
        end ||
        start ||
        type ||
        deviceType ||
        deviceType === 0 ||
        userEmail ||
        userEmail === undefined
      ) {
        await getAllStatistic();
      }
      if (
        compareEnd ||
        compareStart ||
        compareType ||
        compareDeviceType ||
        compareUserEmail ||
        compareUserEmail === undefined
      ) {
        await getAllCompareStatistic();
      }
    };
    fetch();
  }, [
    end,
    start,
    compareEnd,
    compareStart,
    isCompare,
    type,
    compareType,
    deviceType,
    compareDeviceType,
    userEmail,
    compareUserEmail,
  ]);

  useEffect(() => {
    setDecider(false);
    if (start && compareStart && type && compareType) {
      setDecider(true);
      if (isRange && end && compareEnd) {
        setDecider(true);
      } else if (
        (isRange && !end && compareEnd) ||
        (isRange && end && !compareEnd)
      ) {
        setDecider(false);
        setRequiredData(TextObj.FINISH_DATE);
      }
      if (userEmail && compareUserEmail) {
        setDecider(true);
      } else if (
        (userEmail && !compareUserEmail) ||
        (!userEmail && compareUserEmail)
      ) {
        setDecider(false);
        setRequiredData(TextObj.USER_SEARCH);
      }
    }
    if (!start || !compareStart) {
      setRequiredData(TextObj.BAGINNING_DATE);
    }
    if (!type || !compareType) {
      setRequiredData(TextObj.PRIORITY_SEARCH);
    }
  }, [
    compareDeviceType,
    compareEnd,
    compareStart,
    compareType,
    compareUserEmail,
    deviceType,
    end,
    isRange,
    start,
    type,
    userEmail,
  ]);

  return (
    <div style={{ paddingLeft: "300px", paddingTop: "100px" }}>
      <Space direction="vertical" size="middle">
        <CheckBox setIsCompare={setIsCompare} />
        <RadioGroupButtons isRange={isRange} setIsRange={setIsRange} />
        <div style={{ display: "flex" }}>
          <StatisticForm isRange={isRange} />
          {isCompare && (
            <StatisticForm isRange={isRange} isCompare={isCompare} />
          )}
        </div>
      </Space>

      {isCompare && decider ? (
        <div className="mainChartsPositions">
          <ComparePieCharts />
        </div>
      ) : isCompare ? (
        <div className="mainChartsPositions">{`${TextObj.REQUIRED_DATA} - ${requiredData}`}</div>
      ) : (
        <div className="mainChartsPositions">
          <PieCharts data={statistics} type={type} />
        </div>
      )}
    </div>
  );
};

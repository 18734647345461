import { store } from "../../constants/store";
import { createCall } from "../base/base";
import { BASE_METHODS } from "../../constants/features/actionTypes";
import {
  setCompareStatistic,
  setStatActionFinished,
  setStatActionStart,
} from "../../constants/reducers/statistic";

export const getAllCompareStatistic = async () => {
  store.dispatch(setStatActionStart());
  const {
    compareStart,
    compareEnd,
    compareType,
    compareDeviceType,
    compareUserEmail,
  } = store.getState().statistic;
  const queryParams = new URLSearchParams();

  if (compareStart) queryParams.append("start", compareStart.toISOString());
  if (compareEnd) queryParams.append("end", compareEnd.toISOString());
  if (compareType) queryParams.append("type", compareType);
  if (compareDeviceType) queryParams.append("osType", compareDeviceType);
  if (compareUserEmail) queryParams.append("userEmail", compareUserEmail);

  const dataToSend = {
    url: "statistic",
    method: BASE_METHODS.GET,
    contentType: "application/json",
    queryParams: queryParams,
  };

  const request = await createCall(dataToSend);
  try {
    const response = request;
    store.dispatch(setCompareStatistic(response.data.statistics));
    store.dispatch(setStatActionFinished());
    return response;
  } catch (error) {
    store.dispatch(setStatActionFinished());
    return null;
  }
};

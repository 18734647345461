import { useDispatch, useSelector } from "react-redux";
import { setSelectedId } from "../../reducers/user";
import { useCallback, useMemo } from "react";
import { Button, Popconfirm, Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { deleteUserAction } from "../../../actions/user/delete-user";
import { TextObj } from "../../text";

export const AdminColumns = () => {
  const dispatch = useDispatch();
  const { authenticatedUser } = useSelector((state) => state.login);

  const handleDelete = useCallback(
    async (record) => {
      dispatch(setSelectedId(record.id));
      await deleteUserAction();
    },
    [dispatch]
  );

  return useMemo(() => {
    return [
      {
        title: TextObj.EMAIL,
        key: TextObj.FORM_EMAIL_NAME,
        dataIndex: TextObj.FORM_EMAIL_NAME,
        align: "left",
      },
      {
        title: TextObj.MESSAGES_CREATED_LABEL,
        key: TextObj.MESSAGES_CREATED,
        dataIndex: "created",
        render: (string, data) => {
          return (
            <Space size="small">
              {new Date(data.created * 1000).toLocaleDateString()}
            </Space>
          );
        },
        sorter: (a, b) => a.created - b.created,
        align: "right",
      },
      {
        title: TextObj.MODIFY_LABEL,
        key: TextObj.MODIFY,
        dataIndex: TextObj.MODIFY,
        render: (string, data) => {
          return (
            <Space size="small">
              {new Date(data.modified * 1000).toLocaleDateString()}
            </Space>
          );
        },
        sorter: (a, b) => a.modified - b.modified,
        align: "right",
      },
      {
        title: "",
        key: TextObj.UPDATE,
        dataIndex: TextObj.UPDATE,
        render: (value, record) => (
          <Space size="middle">
            <Popconfirm
              disabled={record.id === authenticatedUser.id}
              title={TextObj.ADMINS_POPUP_CONFIRM_TEXT}
              onConfirm={() => handleDelete(record)}
              okText={TextObj.YES}
              cancelText={TextObj.NO}
            >
              <Button
                className="button"
                type="primary"
                htmlType="submit"
                danger
                disabled={record.id === authenticatedUser.id}
              >
                <DeleteOutlined />
                {TextObj.DELETE_LABEL}
              </Button>
            </Popconfirm>
          </Space>
        ),
        width: 50,
      },
    ];
  }, [authenticatedUser.id, handleDelete]);
};

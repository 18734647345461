import { Col, Row, Table } from "antd";
import { useSelector } from "react-redux";
import { AdminColumns } from "../tableColumns";

export const AdminTable = () => {
  const { users } = useSelector((state) => state.user);

  return (
    <Row style={{ padding: "0.5em" }}>
      <Col span={24}>
        <Table
          style={{ width: "95%" }}
          bordered
          columns={Object.values(AdminColumns())}
          dataSource={users}
          pagination={{
            position: ["bottomCenter"],
            pageSize: 10,
            showSizeChanger: false,
          }}
          locale={{ emptyText: "Nincs adat" }}
        />
      </Col>
    </Row>
  );
};

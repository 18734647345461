import { TextObj } from "../../text";

export const MessageChannels = [
  {
    value: "BASE_CHANNEL",
    label: TextObj.BASE_CHANNEL,
  },
  {
    value: "MAINTENANCE_CHANNEL",
    label: TextObj.MAINTENANCE_CHANNEL,
  },
  {
    value: "SUBSCRIPTION_CHANNEL",
    label: TextObj.SUBSCRIPTION_CHANNEL,
  },
  {
    value: "INSTITUTIONAL_CHANNEL",
    label: TextObj.INSTITUTIONAL_CHANNEL,
  },
];

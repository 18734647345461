import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const RequireAuth = (props) => {
  const { authenticatedUser } = useSelector((state) => state.login);
  if (authenticatedUser === null || authenticatedUser === undefined) {
    return <Navigate to={`/login`} />;
  }
  return props.children;
};
